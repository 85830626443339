import React, { createRef } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const featuredImgFluid = (featuredImage) => {
    return featuredImage.childImageSharp.fluid
  }
  const ref = createRef()

  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} keywords={'Erik Altman, blog, article, '+frontmatter.tags.join(', ')}/>
      <div ref={ref} className="bg-light py-4">
        <div className="container px-3 pb-4" style={{ maxWidth: 750 }}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-4">
              <li className="breadcrumb-item">
                <Link to={'/blog/'}>Blog</Link>
                </li>
              <li className="breadcrumb-item" aria-current="page">
                {frontmatter.title}
                </li>
            </ol>
          </nav>
          <div className="card shadow-sm">
            <Img className="card-img-top" style={{ maxWidth: 750 }} fluid={featuredImgFluid(frontmatter.featuredImage)} alt="featured image" />
            <div className="card-body">
              <h1 className="card-title">{frontmatter.title}</h1>
              <p>
                by <a href="/">{frontmatter.author}</a> on {frontmatter.date}
              </p>
              <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(
      frontmatter: {path: {eq: $path } }
    ) {
      html
      frontmatter {
        path
        date
        author
        title
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`